@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}

.dropzone{
    height: 200px
}

.jodit-ui-group{
    margin-top: 0px !important
}
body{font-family: 'Roboto', sans-serif;}

.appContainer{
  padding:0px;
  margin:0px;
  height:100%;
  box-sizing:border-box;
}

.searchHolder{
  display:flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 350px;
  padding: 5px 10px 5px 10px;
  margin-left:auto;
  background-color: #378e81;
  box-sizing:border-box;
  margin-right:25px;
}

.searchHolder input{
  width:290px;
  color:#ffffff; 
  padding-left:10px;
}

.searchHolder .searchIcon{
  margin-top:2px;
}

.contentContainer{
    display: flex;
    overflow: hidden;
    border-radius: 0px;
    flex-direction: column;
    border: none;
    width: 100%;
    align-items: stretch;
}

.fullHeight{
  height:100%;
}

.rotate-90 {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.modalBoxMultipleSelect{
  margin-top:20px;
}

.verticalMenu{
  display: 'flex' !important;
  width: 10% !important
}

.baseContainer{
  display:flex;
  flex-direction: column;
  height: 100%;
  background-color: #FBFBFA;
}

.toastSuccess{
  background-color: #34aa44 !important
}

.toastError{
  background-color: #e6492d !important
}

.toastErrorBody > div, .toastSuccessBody > div {
    height: auto
}

.Toastify__toast{
    min-height: 50px !important
}

.Toastify__close-button{
    margin-top: 9px;
    padding: 0px 12px 0px 10px;
    font-weight: normal
}

.Toastify__toast-container{
    width: 360px !important
}
